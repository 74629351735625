<script>
export default {
  methods: {
    getUserName(user) {
      if (!user || !user.details) {
        return 'Незарегистрированный пользователь';
      }

      const lastName = user.details.lastName || '';
      const firstName = user.details.firstName ? `${user.details.firstName.slice(0, 1).toUpperCase()}.` : '';
      const middleName = user.details.middleName ? `${user.details.middleName.slice(0, 1).toUpperCase()}.` : '';
      
      if (!lastName && !firstName && !middleName) {
        return 'Имя отсутствует';
      }

      return `${lastName} ${firstName} ${middleName}`;
    }
  }
}
</script>
