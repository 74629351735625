<template>
  <div class="ip-select">
    <div
      v-if="withLabel"
      class="ip-select-label">
      <span v-html="label"></span>
      <span
        v-if="showRequiredIcon"
        class="required-icon">
          *
      </span>
    </div>
    <div
      v-click-outside="hide"
      class="ip-select-wrapper"
      :class="{
        'open': isOpened,
        'disabled': disabled,
        'invalid': invalid
      }">
      <div
        class="ip-select-selected"
        @click="isOpened = true">
        <span
          class="ip-select-placeholder"
          v-if="!selected">
            {{ placeholder }}
        </span>
        <slot v-else name="selected" v-bind:selected="selected">
          <span>
            {{ selected.text }}
          </span>
        </slot>
        <div class="ip-select-icon">
          <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.295 0.897461L3 3.18746L0.705 0.897461L0 1.60246L3 4.60246L6 1.60246L5.295 0.897461Z"
              fill="black"
              fill-opacity="0.54"
            />
          </svg>
        </div>
      </div>
      <div
        class="ip-select-list"
        :class="open">
        <div
          v-for="(option, index) in options"
          :key="index"
          class="ip-select-list-item"
          @click="selectOption(option)">
          <slot name="option" v-bind:option="option">
            {{ option.text }}
          </slot>
        </div>
        <div v-if="!options.length" class="ip-select-list-item" @click="isOpened = false">
          No data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  props: {
    withLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'label'
    },
  
    placeholder: {
      type: String,
      default: 'placeholder'
    },
    options: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    showRequiredIcon: {
      type: Boolean,
      default: false
    },
    selectedItem: {
      type: [String, Number]
    },
    open: {
      type: String,
      default: 'bottom'
    },
    optionSlot: {
      type: Boolean,
      default: false
    }
  },

  directives: {
    ClickOutside
  },

  data() {
    return {
      selected: null,
      isOpened: false
    };
  },

  mounted() {
    if (this.selectedItem) {
      this.selected = this.options.find(el => el.value === this.selectedItem);
    }
  },

  methods: {
    /**
     * @param {object} option
     * @return {void}
     */
    selectOption(option) {
      this.selected = option;
      this.$emit('onSelect', option.value)
      this.hide();
    },

    /**
     * @return {void}
     */
    hide() {
      this.isOpened = false;
    }
  },

  watch: {
    options() {
      if (this.selectedItem) {
        this.selected = this.options.find(el => el.value === this.selectedItem);
      }
    },

    selectedItem() {
      this.selected = this.options.find(el => el.value === this.selectedItem);
    }
  }
};
</script>

<style scoped lang="scss">
.ip-select {
  &-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $darkGray;
    margin-bottom: 3px;
    display: flex;
    ::v-deep .ip-tooltip{
      margin-left: 1px;
    }
  }

  &-wrapper {
    position: relative;

    &.open {
      .ip-select-icon {
        z-index: 20;

        svg {
          transform: rotate(180deg);
        }
      }

      .ip-select-list {
        display: block;
      }
    }

    &.selected {
      .ip-select-selected {
        border-color: $blue;
      }
    }

    &.disabled {
      .ip-select-selected {
        background: $wildSand;
        pointer-events: none;
      }
    }

    &.invalid {
      .ip-select-selected {
        border-color: $red;
      }
    }
  }

  &-selected {
    height: 30px;
    background: $white;
    border: 1px solid $lightGray;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 24px 0 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $black;
    border-radius: 6px;
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-list {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    display: none;
    max-height: 150px;
    overflow: auto;
    background: $white;
    border: 1px solid $blue;
    box-sizing: border-box;
    border-radius: 6px;
    &.bottom{
      top: 0;
    }
    &.top{
      bottom: 0;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $blue;
      outline: 1px solid $gray;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba($black, 0.3);
    }
  }

  &-list-item {
    min-height: 28px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    padding: 0 12px;
    box-sizing: border-box;
    margin-bottom: 4px;
    
    &:first-child{
      margin-bottom: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &:hover {
      background: $blue;
      color: $white;
      cursor: pointer;
    }
  }

  &-placeholder {
    user-select: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $midGray;
  }

  &-icon {
    position: absolute;
    right: 12px;
    top: 3px
  }

  .option{
    display: flex;
    align-items: center;
    width: 100%;
  }
  ::v-deep .status{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $persianGreen;
    margin-right: 6px;
    &.inactive{
      background: $persianRed;
    }
  }
}
</style>
